import { BookTopicTypes } from '~/types/app/book';
import { SearchFacetParamName } from '~/types/app/search';
import { EnvironmentsTypes } from '~/types/util-types';
import { featureTest } from '~/utils/environment';

type GlobalConfigTypes = {
    maintenance: boolean;
    clientVersion: string;
    supportEmail: string;
    ttsEnabled: boolean;
    publicKeys: {
        [key: string]: string;
    };
    privateAreas: Array<string>;
    onlyPublicAreas: Array<string>;
    paths: {
        search: string;
    };
    readableEncryptionKey: string;
    bookFormats: Array<{ key: string; label: string; facet: SearchFacetParamName; value: string }>;
    availability: Array<{ key: string; label: string; facet: SearchFacetParamName; value: string }>;
    publishers: Array<{ key: string; label: string; facet: SearchFacetParamName; value: string }>;
    languages: Array<{ key: string; label: string; facet: SearchFacetParamName; value: string }>;
    topics: Array<BookTopicTypes>;
    curriculumVersions: Record<EnvironmentsTypes, { version: string; name: string }>;
    featureFlags: {
        overrideCtrlF: boolean;
        showPriceCalculator: boolean;
        isErrorTrackingEnvironment: boolean;
        isAnalyticsEnvironment: boolean;
        showCampaignSubscription: boolean;
        showAiPrototype: boolean;
        increasePrivacyForOrgUsers: boolean;
        allowOrgUsersToSuggestBooks: boolean;
        loadReadableWithoutMetadata: boolean;
        readablePDFAsDefault: boolean;
        disablePspdfkitEdit: boolean;
        inFlightEncryption: boolean;
    };
    subscriptionConsumptionTracking: {
        unitLength: number;
        idleTimeout: number;
    };
    darkModeCookieExpiryTime: number;
    subscriptionModalCookieExpiryTime: number;
    forcedSignoutCookieExpiryTime: number;
    readable: {
        contentWidth: {
            narrow: number;
            normal: number;
            wide: number;
        };
    };
};

const globalConfig: GlobalConfigTypes = {
    maintenance: false,
    clientVersion: 'allvit/web/2.0',
    supportEmail: 'support@allvit.no',
    ttsEnabled: true,
    publicKeys: {
        CRYSTALLIZE_TENANT_IDENTIFIER: 'allvit',
        GOOGE_TAG_MANAGER_ID: 'GTM-PQZFGHM',
        HUBSPOT_PORTAL_ID: '25797771',
        PENDO_ID: 'ad901467-c165-483e-77ca-0eac94507815',
        FACEBOOK_PAGE_VERIFICATION: 'bh1d79wnb8lqb5a0o7o2gnbcd3rh5n',
        GOOGLE_SITE_VERIFICATION: 'dHEUXdUV0cdfvww7z84CSaMNDhVbpelRwp1bNv_Q-Tg',
    },
    privateAreas: ['/bokhylle', '/profil', '/kode'],
    onlyPublicAreas: ['/logg-inn'],
    paths: {
        search: '/public/search',
    },
    readableEncryptionKey: '2b7e151628aed2a6abf7158809cf4f3c2b7e151628aed2a6abf7158809cf4f3c',
    availability: [
        {
            key: 'allvitplus',
            label: 'Allvit+',
            facet: SearchFacetParamName.IncludedInSubscription,
            value: '1',
        },
        {
            key: 'free',
            label: 'Gratis',
            facet: SearchFacetParamName.FreeBook,
            value: '1',
        },
        {
            key: 'rent',
            label: 'Korttidskjøp',
            facet: SearchFacetParamName.Rent,
            value: '-1',
        },
        {
            key: 'buy',
            label: 'Kjøp',
            facet: SearchFacetParamName.Buy,
            value: '-1',
        },
    ],
    bookFormats: [
        {
            key: 'epub',
            label: 'EPUB',
            facet: SearchFacetParamName.FileFormat,
            value: 'EPUB',
        },
        {
            key: 'pdf',
            label: 'PDF',
            facet: SearchFacetParamName.FileFormat,
            value: 'PDF',
        },
    ],
    languages: [
        {
            key: '79a8y',
            label: 'Norsk, bokmål',
            facet: SearchFacetParamName.LanguagesNo,
            value: 'Bokmål',
        },
        {
            key: '98hasd',
            label: 'Norsk, nynorsk',
            facet: SearchFacetParamName.LanguagesNo,
            value: 'Nynorsk',
        },
        {
            key: 'jsklld',
            facet: SearchFacetParamName.LanguagesNo,
            label: 'Engelsk',
            value: 'Engelsk',
        },
    ],
    publishers: [
        { key: 'p01', label: 'Akademika', facet: SearchFacetParamName.Publishers, value: 'Akademika' },
        { key: 'p02', label: 'Bokvennen', facet: SearchFacetParamName.Publishers, value: 'Bokvennen' },
        { key: '0008', label: 'Cappelen Damm', facet: SearchFacetParamName.Publishers, value: 'Cappelen Damm' },
        {
            key: 'p03',
            label: 'Cappelen Damm akademisk',
            facet: SearchFacetParamName.Publishers,
            value: 'Cappelen Damm akademisk',
        },
        { key: 'p04', label: 'Eide forlag', facet: SearchFacetParamName.Publishers, value: 'Eide forlag' },
        { key: '0522', label: 'Fagbokforlaget', facet: SearchFacetParamName.Publishers, value: 'Fagbokforlaget' },
        { key: '3425', label: 'Forlaget Rødt!', facet: SearchFacetParamName.Publishers, value: 'Forlaget Rødt!' },
        { key: '0002', label: 'Gyldendal', facet: SearchFacetParamName.Publishers, value: 'Gyldendal' },
        {
            key: 'p05',
            label: 'Gyldendal akademisk',
            facet: SearchFacetParamName.Publishers,
            value: 'Gyldendal akademisk',
        },
        {
            key: 'p06',
            label: 'Gyldendal arbeidsliv',
            facet: SearchFacetParamName.Publishers,
            value: 'Gyldendal arbeidsliv',
        },
        {
            key: 'p07',
            label: 'Gyldendal juridisk',
            facet: SearchFacetParamName.Publishers,
            value: 'Gyldendal juridisk',
        },
        { key: '4602', label: 'Info vest forlag', facet: SearchFacetParamName.Publishers, value: 'Info vest forlag' },
        { key: 'p08', label: 'John Grieg forlag', facet: SearchFacetParamName.Publishers, value: 'John Grieg forlag' },
        {
            key: 'p09',
            label: 'Kulturhistorisk museum, Arkeologisk seksjon',
            facet: SearchFacetParamName.Publishers,
            value: 'Kulturhistorisk museum, Arkeologisk seksjon',
        },
        {
            key: 'p10',
            label: 'Kulturhistorisk museum, Fornminneseksjonen',
            facet: SearchFacetParamName.Publishers,
            value: 'Kulturhistorisk museum, Fornminneseksjonen',
        },
        {
            key: 'p11',
            label: 'Kulturhistorisk museum, Universitetet i Oslo',
            facet: SearchFacetParamName.Publishers,
            value: 'Kulturhistorisk museum, Universitetet i Oslo',
        },
        {
            key: 'p12',
            label: 'Landslaget for norskundervisning',
            facet: SearchFacetParamName.Publishers,
            value: 'Landslaget for norskundervisning',
        },
        { key: 'p13', label: 'NOASP', facet: SearchFacetParamName.Publishers, value: 'NOASP' },
        {
            key: 'p14',
            label: 'NTNU, Faculty of Engineering Science and Technology',
            facet: SearchFacetParamName.Publishers,
            value: 'NTNU, Faculty of Engineering Science and Technology',
        },
        {
            key: '4713',
            label: 'Norges luftsportforbund',
            facet: SearchFacetParamName.Publishers,
            value: 'Norges luftsportforbund',
        },
        {
            key: 'p15',
            label: 'Pedlex',
            facet: SearchFacetParamName.Publishers,
            value: 'Pedlex',
        },
        { key: 'p16', label: 'Portal', facet: SearchFacetParamName.Publishers, value: 'Portal' },
        { key: 'p17', label: 'SAGE', facet: SearchFacetParamName.Publishers, value: 'SAGE' },
        { key: '0024', label: 'Samlaget', facet: SearchFacetParamName.Publishers, value: 'Samlaget' },
        {
            key: 'p18',
            label: 'Scandinavian University Press',
            facet: SearchFacetParamName.Publishers,
            value: 'Scandinavian University Press',
        },
        {
            key: 'p19',
            label: 'Senter for Ibsenstudier',
            facet: SearchFacetParamName.Publishers,
            value: 'Senter for Ibsenstudier',
        },
        {
            key: 'p20',
            label: 'Solum',
            facet: SearchFacetParamName.Publishers,
            value: 'Solum',
        },
        {
            key: 'p21',
            label: 'Solum Bokvennen',
            facet: SearchFacetParamName.Publishers,
            value: 'Solum Bokvennen',
        },
        {
            key: 'p22',
            label: 'Symra',
            facet: SearchFacetParamName.Publishers,
            value: 'Symra',
        },
        {
            key: 'p23',
            label: 'Tano Aschehough',
            facet: SearchFacetParamName.Publishers,
            value: 'Tano Aschehough',
        },
        {
            key: 'p24',
            label: 'UiO. Kulturhistorisk museum',
            facet: SearchFacetParamName.Publishers,
            value: 'UiO. Kulturhistorisk museum',
        },
        {
            key: 'p25',
            label: 'Unipub forlag',
            facet: SearchFacetParamName.Publishers,
            value: 'Unipub forlag',
        },
        {
            key: '0513',
            label: 'Universitetsforlaget',
            facet: SearchFacetParamName.Publishers,
            value: 'Universitetsforlaget',
        },
        {
            key: 'p26',
            label: 'Vidarforlaget',
            facet: SearchFacetParamName.Publishers,
            value: 'Vidarforlaget',
        },
        {
            key: 'p27',
            label: 'Yrkeslitteratur',
            facet: SearchFacetParamName.Publishers,
            value: 'Yrkeslitteratur',
        },
    ],
    topics: [
        {
            key: '1oasd',
            emoji: '👶',
            label: 'Barnehagelærerutdanning',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Barnehagelærerutdanning',
            routeId: 'barnehagelaererutdanning',
        },
        {
            key: '7oasd',
            emoji: '💻',
            label: 'Data- og informasjonsteknologi',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Data- og informasjonsteknologi',
            routeId: 'data-og-informasjonsteknologi',
        },
        {
            key: 'oasd7',
            emoji: '🤔',
            label: 'Filosofi og religion',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Filosofi og religion',
            routeId: 'filosofi-og-religion',
        },
        {
            key: 'oasd4',
            emoji: '🔬',
            label: 'Forskning og metode',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Forskning og metode',
            routeId: 'forskning-og-metode',
        },
        {
            key: 'oasdd',
            emoji: '🐼',
            label: 'Geografi og miljøkunnskap',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Geografi og miljøkunnskap',
            routeId: 'geografi-og-miljokunnskap',
        },
        {
            key: 'oasd5',
            emoji: '⛏️',
            label: 'Historie og arkeologi',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Historie og arkeologi',
            routeId: 'historie-og-arkeologi',
        },
        {
            key: 'oasd2',
            emoji: '⚖️',
            label: 'Jus',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Jus',
            routeId: 'jus',
        },
        {
            key: '9oasd',
            emoji: '🎭',
            label: 'Kunst og kultur',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Kunst og kultur',
            routeId: 'kunst-og-kultur',
        },
        {
            key: '2oasd',
            emoji: '🚜',
            label: 'Landbruk og havbruk',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Landbruk og havbruk',
            routeId: 'landbruk-og-havbruk',
        },
        {
            key: 'oasz7',
            emoji: '🧮',
            label: 'Matematikk og naturvitenskap',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Matematikk og naturvitenskap',
            routeId: 'matematikk-og-naturvitenskap',
        },
        {
            key: 'oasd3',
            emoji: '🫁',
            label: 'Medisin',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Medisin',
            routeId: 'medisin',
        },
        {
            key: '0oasd',
            emoji: '🧑‍🏫',
            label: 'Pedagogikk og lærerutdanning',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Pedagogikk og lærerutdanning',
            routeId: 'pedagogikk-og-laererutdanning',
        },
        {
            key: '6oasd',
            emoji: '🧠',
            label: 'Psykologi',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Psykologi',
            routeId: 'psykologi',
        },
        {
            key: '5oasd',
            emoji: '🏛️',
            label: 'Samfunnsvitenskap',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Samfunnsvitenskap',
            routeId: 'samfunnsvitenskap',
        },
        {
            key: '4oasd',
            emoji: '🗞️',
            label: 'Språk, litteratur og medievitenskap',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Språk, litteratur og medievitenskap',
            routeId: 'sprak-litteratur-og-medievitenskap',
        },
        {
            key: '3oasd',
            emoji: '😷',
            label: 'Sykepleie og helsefag',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Sykepleie og helsefag',
            routeId: 'sykepleie-og-helsefag',
        },
        {
            key: 'oasd1',
            emoji: '📐',
            label: 'Tekniske fag og ingeniørstudier',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Tekniske fag og ingeniørstudier',
            routeId: 'tekniske-fag-og-ingeniorstudier',
        },
        {
            key: 'oasd6',
            emoji: '🤑',
            label: 'Økonomi og ledelse',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Økonomi og ledelse',
            routeId: 'okonomi-og-ledelse',
        },
        {
            key: 'hajks',
            emoji: '🤷‍♂️',
            label: 'Annet',
            facet: SearchFacetParamName.AllvitSubjects,
            value: 'Annet',
            routeId: 'annet',
        },
    ],
    curriculumVersions: {
        [EnvironmentsTypes.Local]: { version: '10', name: 'Høsten 2024' },
        [EnvironmentsTypes.BetaTest]: { version: '10', name: 'Høsten 2024' },
        [EnvironmentsTypes.BetaStage]: { version: '10', name: 'Høsten 2024' },
        [EnvironmentsTypes.BetaProd]: { version: '10', name: 'Høsten 2024' },
        [EnvironmentsTypes.Test]: { version: '10', name: 'Høsten 2024' },
        [EnvironmentsTypes.Stage]: { version: '10', name: 'Høsten 2024' },
        [EnvironmentsTypes.Prod]: { version: '10', name: 'Høsten 2024' },
    },
    featureFlags: {
        overrideCtrlF: featureTest([]),
        showPriceCalculator: featureTest([]),
        isErrorTrackingEnvironment: featureTest([
            EnvironmentsTypes.Local,
            EnvironmentsTypes.BetaTest,
            EnvironmentsTypes.BetaStage,
            EnvironmentsTypes.BetaProd,
            EnvironmentsTypes.Test,
            EnvironmentsTypes.Stage,
            EnvironmentsTypes.Prod,
        ]),
        isAnalyticsEnvironment: featureTest([EnvironmentsTypes.BetaProd, EnvironmentsTypes.Prod]),
        showCampaignSubscription: featureTest([
            EnvironmentsTypes.Local,
            EnvironmentsTypes.BetaTest,
            EnvironmentsTypes.BetaStage,
            EnvironmentsTypes.BetaProd,
            EnvironmentsTypes.Test,
            EnvironmentsTypes.Stage,
            EnvironmentsTypes.Prod,
        ]),
        showAiPrototype: featureTest([]),
        increasePrivacyForOrgUsers: featureTest([
            EnvironmentsTypes.Local,
            EnvironmentsTypes.BetaTest,
            EnvironmentsTypes.BetaStage,
            EnvironmentsTypes.BetaProd,
        ]),
        allowOrgUsersToSuggestBooks: featureTest([]),
        loadReadableWithoutMetadata: featureTest([
            EnvironmentsTypes.Local,
            EnvironmentsTypes.BetaTest,
            EnvironmentsTypes.Test,
        ]),
        readablePDFAsDefault: featureTest([
            // EnvironmentsTypes.Local,
            // EnvironmentsTypes.BetaTest,
            EnvironmentsTypes.Test,
        ]), //  Also converting annotations pspdfkit -> Readable
        disablePspdfkitEdit: featureTest([EnvironmentsTypes.Local, EnvironmentsTypes.Test]), // CRUD disabled on annotations and bookmarks. LRP disabled
        inFlightEncryption: featureTest([
            EnvironmentsTypes.Local,
            EnvironmentsTypes.BetaTest,
            EnvironmentsTypes.BetaStage,
            EnvironmentsTypes.BetaProd,
            EnvironmentsTypes.Test,
            EnvironmentsTypes.Stage,
            EnvironmentsTypes.Prod,
        ]),
    },
    subscriptionConsumptionTracking: {
        unitLength: 5,
        idleTimeout: 10000,
    },
    darkModeCookieExpiryTime: 1000 * 60 * 60 * 24 * 30,
    subscriptionModalCookieExpiryTime: 1000 * 60 * 60 * 24 * 30,
    forcedSignoutCookieExpiryTime: 1000 * 60 * 10,
    readable: {
        contentWidth: {
            narrow: 880,
            normal: 690,
            wide: 560,
        },
    },
};

export default globalConfig;
